import { type Content } from "./Content";

export enum DocumentType {
  mindmap = "mindmap",
  note = "note",
  board = "board",
}

export namespace DocumentType {
  export function contains(string: string | string[]): boolean {
    if (Object.values(DocumentType).includes(string as DocumentType)) {
      return true;
    }
    return false;
  }
  export function getEnum(string: string | string[]): DocumentType {
    if (Object.values(DocumentType).includes(string as DocumentType)) {
      return string as DocumentType;
    }
    throw new Error("Invalid DocumentType");
  }

  export function getEnumArray(string: string | string[]): DocumentType[] {
    if (Array.isArray(string)) {
      return string.map((s) => getEnum(s));
    }
    return [getEnum(string)];
  }

  export function isMindmap(type: DocumentType): boolean {
    return type === DocumentType.mindmap;
  }
  export function isNote(type: DocumentType): boolean {
    return type === DocumentType.note;
  }
  export function isCanvas(type: DocumentType): boolean {
    return type === DocumentType.board;
  }
  export function all(): DocumentType[] {
    return [DocumentType.mindmap, DocumentType.note, DocumentType.board];
  }

  export function getTitle(type: DocumentType): string {
    switch (type) {
      case DocumentType.mindmap:
        return "Mindmap";
      case DocumentType.note:
        return "Note";
      case DocumentType.board:
        return "Canvas";
    }
  }
}

export enum RetentionMode {
  content = "content",
  question = "question",
}

export interface Annotation {
  type: string;
  content: string;
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IDocument<T extends DocumentType> {
  _id?: string;
  _key?: string;
  title: string;
  content?: Content<T> | Content<T>[];
  fullText?: string;
  tags?: string[];
  references?: string[];
  pinned?: boolean;
  draft?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  type: T;
  trashed: boolean;
  readonly linkedDocuments?: string[] | IDocument<DocumentType>[];
  annotations?: Annotation[];
  retention?: {
    lastClicked: Date;
    counts?: [
      {
        mode: RetentionMode;
        successCount: number;
        successCondition?: string;
        failCount?: number;
      },
    ];
    lastSent?: Date;
    // lastMarkedAsRead?: Date;
    sentCount?: number;
    // markedAsReadCount?: number;
    token?: string;
  };
}

export function isMindmap(
  document: IDocument<DocumentType>,
): document is IDocument<DocumentType.mindmap> {
  return document.type === DocumentType.mindmap;
}

export function isNote(
  document: IDocument<DocumentType>,
): document is IDocument<DocumentType.note> {
  return document.type === DocumentType.note;
}

export function isCanvas(
  document: IDocument<DocumentType>,
): document is IDocument<DocumentType.board> {
  return document.type === DocumentType.board;
}

export function contentIsArray<T extends DocumentType>(
  content: Content<T> | Content<T>[] | undefined,
): content is Content<T>[] {
  return Array.isArray(content);
}
